<template>
  <v-img
    :alt="image"
    :aspect-ratio="10/3"
    :src="mobile && !showOnMobile ? '' : imageUrl"
    :class="loanType"
    class="cw-hero"
    height="auto"
    dark
  >
    <template #placeholder>
      <v-row
        v-if="!mobile"
        class="#placeholder fill-height ma-0"
        align="center"
        justify="center"
      >
        <v-progress-circular
          color="primary"
          indeterminate
        />
      </v-row>
    </template>

    <v-container
      v-if="$slots['hero-title'] || $slots['hero-text']"
      class="cw-hero__container pa-0 fill-height"
    >
      <v-row>
        <v-col
          :class="animation"
          class="cw-hero__slogan d-flex relative align-center justify-center"
          cols="12"
          md="6"
        >
          <div
            :class="`text-${animation}`"
            class="cw-hero__slogan__content"
          >
            <h2
              v-if="$slots['hero-title']"
              :class="$vuetify.breakpoint.smAndUp ? 'text-h4' : 'text-h6'"
              class="cw-hero__slogan__title"
            >
              <slot name="hero-title"/>
            </h2>
            <p
              v-if="$slots['hero-text']"
              class="cw-hero__slogan__text white--text mb-0"
            >
              <slot name="hero-text"/>
            </p>
          </div>
          <div class="cw-hero__slant">
            <component :is="slant"/>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-img>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CwHero',

  props: {
    image: {
      type: String,
      default: () => '',
    },

    showOnMobile: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    animation: 'slide-in-right',
    imageUrl: '',
  }),

  computed: {
    ...mapGetters({
      page: 'application/getCurrentPage',
      product: 'application/getProduct',
      mobile: 'getMobile',
    }),

    brand() {
      if (!this.product || !this.product.brand) return '';
      return this.product.brand.toLowerCase();
    },

    loanType() {
      if (!this.product || !this.product.loanType) return '';
      return this.product.loanType.toLowerCase();
    },

    heroImage() {
      const {
        brand,
        image,
        product: { theme },
      } = this;

      if (image) return image;

      const themeImage = `themes/${theme}/default.jpg`;
      const defaultImage = `${brand}-hero.jpg`;

      return theme ? themeImage : defaultImage;
    },

    slant() {
      if (!this.brand || this.brand === 'saldo') return '';
      return () => import('@shared/assets/images/' + this.brand + '_slant.svg'); // eslint-disable-line
    },
  },

  watch: {
    /**
     * Load appropriate logo when the product has been recieved
     */
    product: {
      async handler(product) {
        if (!product.brand) return;
        this.imageUrl = await this.heroImageUrl();
      },
      deep: true,
      immediate: true,
    },
  },

  async beforeMount() {
    this.$bus.$on('HERO_SLIDE_OUT', () => {
      this.animation = this.brand === 'saldo' ? 'slide-out-right' : 'slide-out';
    });
  },

  methods: {
    async heroImageUrl() {
      try {
        const url = await import('@shared/assets/images/hero/' + this.heroImage); // eslint-disable-line
        return url.default;
      } catch (error) {
        // display the default image if theme image loading fails
        const defaultImage = `${this.brand}-hero.jpg`;
        const url = await import('@shared/assets/images/hero/' + defaultImage); // eslint-disable-line
        return url.default;
      }
    },
  },
};
</script>

<style lang="scss">
.cw-hero {
  &__container {
    max-width: 100% !important;
    padding: 0;
    width: 100% !important;
  }

  &__slant {
    position: absolute;
    top: 0;
    right: -48px;
    width: 48px;
    height: 100%;

    svg {
      height: 100%;
      width: 48px;
    }
  }

  &__slogan {
    height: 100%;
    min-height: 170px;
    position: relative;
    transform: translateX(-100%);

    @include mq($from: sm) {
      min-height: 280px;
    }

    @include mq($from: md) {
      min-height: 360px;
    }

    @include mq($from: lg) {
      min-height: 420px;
    }

    @include mq($from: xl) {
      min-height: 480px;
    }

    &__content {
      opacity: 0;
      padding: 16px;

      @include mq($from: sm) {
        padding: 32px 64px;
      }
    }

    &__title {
      color: #fff;
      font-weight: 700;
      text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.15);
    }

    &__text {
      font-size: 14px;
      font-weight: 700;
      text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.15);

      @include mq($from: sm) {
        font-size: 16px;
      }
    }
  }
}

.limiitti,
.saldo {
  .cw-hero {
    &__slogan {
      &__title {
        line-height: 1.25 !important;
        margin-bottom: 32px;
        position: relative;

        @include mq($from: sm) {
          margin-bottom: 42px;
        }
      }
    }
  }
}

.limiitti {
  .cw-hero {
    &__slogan {
      background: linear-gradient(rgba(0, 177, 235, 0.9), rgba(0, 136, 221, 0.9));
    }
  }
}

.saldo {
  .cw-hero {
    &__slant {
      left: -48px;
      right: auto;
      transform: scaleX(-1);
    }

    &__slogan {
      background: #370B45;

      @include mq($from: md) {
        min-height: 480px;
      }

      @include mq($from: lg) {
        min-height: 560px;
      }

      @include mq($from: xl) {
        min-height: 560px;
      }
    }

    .jumbotron__image {
      max-width: 125%;

      @include mq($from: md) {
        top: 60%;
      }

      @include mq($from: lg) {
        top: 55%;
      }
    }
  }
}

.vippi {
  .cw-hero {
    &__slogan {
      background: linear-gradient(rgba(35, 49, 155, 0.9), rgba(12, 19, 109, 0.9));

      &__title {
        line-height: 1.25 !important;
        margin-bottom: 32px;
        position: relative;

        @include mq($from: sm) {
          margin-bottom: 42px;
        }

      }
    }

    .v-image__image {
      position: absolute;
      top: 30px;
      left: 53.5%;
      min-width: initial;
      max-width: 500px;
      transform: none;
      background-position: top center !important;
      background-size: 80%;
    }
  }
}

.slide-in {
  animation: 0.5s ease-in 0s forwards slide-in;
}

.slide-in-right {
  animation: 0.5s ease-in 0s forwards slide-in-right;
}

.slide-out {
  animation: 0.5s ease-out 0s forwards slide-out;
}

.slide-out-right {
  animation: 0.5s ease-out 0s forwards slide-out-right;
}

.text-slide-in {
  animation: 0.5s ease-in 0.5s forwards text-slide-in;
}

.text-slide-in-right {
  animation: 0.5s ease-in 0.5s forwards text-slide-in-right;
}

.text-slide-out {
  animation: 0.5s ease-out 0.5s forwards text-slide-out;
}

.businessinstalmentloan, .continuousloan, .directcarloan, .instalmentloan {
  .v-image__image, .v-image__placeholder {
    width: 50%;
    left: 50%;
  }
}

@keyframes slide-in {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(0%); }
}

@keyframes slide-in-right {
  0% { transform: translateX(100%); }
  100% { transform: translateX(0%); }
}

@keyframes slide-out {
  0% { transform: translateX(0%); }
  100% { transform: translateX(-100%); }
}

@keyframes slide-out-right {
  0% { transform: translateX(0%); }
  100% { transform: translateX(100%); }
}

@keyframes text-slide-in {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  80% { transform: translateX(10%); }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes text-slide-in-right {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  80% { transform: translateX(-10%); }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes text-slide-out {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }

  20% { transform: translateX(10%); }

  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}

@keyframes text-slide-out-right {
  0% {
    opacity: 1;
    transform: translateX(100%);
  }

  20% { transform: translateX(10%); }

  100% {
    opacity: 0;
    transform: translateX(0%);
  }
}
</style>
